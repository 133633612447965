import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

const SearchableDropdown = ({ items, label, value, onChange, renderMenuItem, disabledItems = [] }) => {
  const handleChange = (event, newValue) => {
    if (newValue && !disabledItems.includes(newValue.pinNumber || newValue.id)) {
      onChange({ target: { value: newValue.pinNumber || newValue.id } });
    }
  };

  return (
    <Autocomplete
      options={items}
      getOptionLabel={(item) => item.name || item.pinNumber}
      value={items.find(item => item.pinNumber === value || item.id === value) || null}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth />
      )}
      renderOption={(props, item) => {
        const isDisabled = disabledItems.includes(item.pinNumber || item.id);
        return (
          <Box 
            component="li" 
            {...props} 
            key={item.pinNumber || item.id} 
            value={item.pinNumber || item.id}
            sx={{ 
              textDecoration: isDisabled ? 'line-through' : 'none',
              pointerEvents: isDisabled ? 'none' : 'auto',
              color: isDisabled ? 'grey' : 'inherit',
              backgroundColor: isDisabled ? 'lightgrey' : 'inherit'
            }}
          >
            {renderMenuItem(item)}
          </Box>
        );
      }}
      isOptionEqualToValue={(option, value) => (option.pinNumber || option.id) === (value.pinNumber || value.id)}
      fullWidth
    />
  );
};

export default SearchableDropdown;
