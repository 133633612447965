import React from 'react';
import { Box, Typography } from '@mui/material';

const ArduinoProMicroDiagram = ({ allPins, selectedPins }) => {
  // Log the props to ensure correct data is passed


  // Function to determine if a pin or its shared pin is selected
  const isPinSelected = (pinNumber) => {
    return selectedPins.includes(String(pinNumber)) || selectedPins.includes(String(findSharedPin(pinNumber)));
  };

  // Function to find the shared pin of a given pin
  const findSharedPin = (pinNumber) => {
    const pin = allPins.find(p => p.pinNumber === pinNumber);
    return pin ? pin.sharedWith : null;
  };

  // Track already rendered pins to avoid duplicates
  const renderedPins = new Set();

  // Group pins and their shared pins together, avoiding duplicates
  const groupedPins = allPins.reduce((acc, pin) => {
    if (renderedPins.has(pin.pinNumber)) {
      return acc; // Skip if this pin has already been rendered
    }

    const sharedPinNumber = findSharedPin(pin.pinNumber);

    if (sharedPinNumber) {
      const existingGroup = acc.find(group => group.includes(sharedPinNumber));
      if (existingGroup) {
        existingGroup.push(pin.pinNumber);
      } else {
        // Ensure the shared pin comes first in the first batch
        if (pin.pinNumber <= 9) {
          acc.push([sharedPinNumber, pin.pinNumber]);
        } else {
          acc.push([pin.pinNumber, sharedPinNumber]);
        }
        renderedPins.add(sharedPinNumber); // Mark shared pin as rendered
      }
    } else {
      acc.push([pin.pinNumber]);
    }

    renderedPins.add(pin.pinNumber); // Mark this pin as rendered
    return acc;
  }, []);

  // Split grouped pins into two batches
  const firstBatch = groupedPins.slice(0, Math.ceil(groupedPins.length / 2) +1);
  const secondBatch = groupedPins.slice(Math.ceil(groupedPins.length / 2) +1).reverse();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6">Arduino Pro Micro</Typography>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {firstBatch.map((group, index) => (
            <Box key={index} display="flex" flexDirection="row" alignItems="center">
              {group.map(pinNumber => (
                <Typography
                  key={pinNumber}
                  style={{
                    color: isPinSelected(pinNumber) ? 'red' : 'green',
                    marginBottom: '5px',
                    marginRight: '10px'
                  }}
                >
                  {pinNumber}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mx={0}>
          <img
            src="https://www.ourpcb.com/wp-content/uploads/2022/11/1-9.jpg"
            alt="Rotated Diagram"
            style={{
              transform: 'rotate(90deg)',

              maxHeight: '200px',
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {secondBatch.map((group, index) => (
            <Box key={index} display="flex" flexDirection="row" alignItems="center">
              {group.map(pinNumber => (
                <Typography
                  key={pinNumber}
                  style={{
                    color: isPinSelected(pinNumber) ? 'red' : 'green',
                    marginBottom: '5px',
                    marginRight: '10px'
                  }}
                >
                  {pinNumber}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ArduinoProMicroDiagram;
