import { Typography, Box } from '@mui/material';


export const elementsSetOpenPleaseChooseABoardModal = [
  {
    type: 'title',
    props: {
      label: "Add New Component Error",
    }
  },
  {
    type: 'description',
    props: {
      label: "Please choose a board first.",
    }
  },
];
export const elementsSetOpenEncoderAlreadyExistsModal = [
  {
    type: 'title',
    props: {
      label: "Encoder Already Exists Error",
    }
  },
  {
    type: 'description',
    props: {
      label: "You can only have one Rotary Encoder per controller. Support for multiple encoders is coming in the future with IO Expansion boards.",
    }
  },
];

export const containerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  width: '100%', // Ensures the container takes the full width of its parent
  maxWidth: '1336px', // Adjust this value as needed to limit the maximum width
  margin: '0 auto', // Centers the container
};

export const itemStyle = {
  flex: '1 1 calc(33.333% - 32px)', // Ensures three items per row with a gap of 16px on each side
  minWidth: '300px', // Ensures a minimum width for each item
  boxSizing: 'border-box',
  marginBottom: '16px', // Adds space between rows
};





export const renderBoardMenuItem = (board) => (
  <Box display="flex" alignItems="center">
    <img src={board.imageUrl} alt={board.name} style={{ width: 40, height: 40, marginRight: 10 }} />
    <Box>
      <Typography variant="subtitle1">{board.name}</Typography>
      <Typography variant="body2">Total Pins: {board.totalPinsNumber}</Typography>
      <Typography variant="body2">Digital: {board.digitalPinsNumber} | Analog: {board.analogPinsNumber}</Typography>
    </Box>
  </Box>
);

export const renderComponentTypeMenuItem = (component) => (
  <Box display="flex" alignItems="center">
    <img src={component.imageUrl} alt={component.name} style={{ width: 40, height: 40, marginRight: 10 }} />
    <Box>
      <Typography variant="subtitle1">{component.name}</Typography>
      <Typography variant="body2">Type: {component.type}</Typography>
    </Box>
  </Box>
);

export const renderComponentFunctionMenuItem = (component) => (
  <Box display="flex" alignItems="center">
    <Box>
      <Typography variant="subtitle1">{component.name}</Typography>
    </Box>
  </Box>
);

export const renderSelectedBoard = (boards, selectedBoard) => {
  const board = boards.find(board => board.id === selectedBoard);
  return board ? (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Box>
          <Typography variant="subtitle1">Selected Board</Typography>
          <img src={board.imageUrl} alt={board.name} style={{ width: 200, marginRight: 10 }} />
        </Box>
        <Box>
          <Typography variant="subtitle1">{board.name}</Typography>
          <Typography variant="body2">Total Pins: {board.totalPinsNumber}</Typography>
          <Typography variant="body2">Digital: {board.digitalPinsNumber} | Analog: {board.analogPinsNumber}</Typography>
        </Box>
      </Box>
    </>
  ) : null;
};