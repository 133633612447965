import React, { useState, useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@mui/material';
import { Header, CustomLoadingIndicator, CustomModal, CustomTooltipComponent } from '../../../components';
import { MdAdd, MdSave, MdDelete } from 'react-icons/md';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import SearchableDropdown from '../../../components/SearchableDropdown';
import ArduinoProMicroDiagram from '../../../components/ArduinoProMicroDiagram'; // Adjust the path based on your project structure

import {  
  elementsSetOpenPleaseChooseABoardModal, 
  elementsSetOpenEncoderAlreadyExistsModal,
  containerStyle, 
  itemStyle,
  renderBoardMenuItem,
  renderComponentTypeMenuItem,
  renderComponentFunctionMenuItem,
  renderSelectedBoard
} from './ElementsNewController';
import { themeColorsUsable } from '../../../data/buildData';

const NewController = () => {
  const { currentColor, loggedIn, loadingIndicatorActive, setLoadingIndicatorActive } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  
  const navigate = useNavigate();

  const [openPleaseChooseABoardModal, setOpenPleaseChooseABoardModal] = useState(false);
  const [openEncoderAlreadyExistsModal, setOpenEncoderAlreadyExistsModal] = useState(false);
  const [componentsArray, setComponentsArray] = useState([]);
  const [boards, setBoards] = useState([]);
  const [componentTypes, setComponentTypes] = useState([]);
  const [midiCCFunctions, setMidiCCFunctions] = useState([]);
  const [midiNoteFunctions, setMidiNoteFunctions] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [availablePins, setAvailablePins] = useState([]);
  const [selectedPins, setSelectedPins] = useState([]);

  const getMidiToNote = (noteNumber) => {
    const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const octave = Math.floor(noteNumber / 12) - 1;
    const note = notes[noteNumber % 12];
    return `${note}${octave}`;
  };

  const populateMIDIArrays = () => {
    let midiCC = [];
    let midiNote = [];
    for (var i = 0; i < 120; i++) {
      midiCC.push({ id: i, name: "CC " + i });
      if (i >= 21 && i <= 118) {
        midiNote.push({ id: i, name: "Note " + i + ": " + getMidiToNote(i) });
      }
    }
    setMidiCCFunctions(midiCC);
    setMidiNoteFunctions(midiNote);
  };

  const handleSelectBoard = (event) => {
    handleResetComponents();
    let pins = [];
    let board = {};
    for (var i = 0; i < boards.length; i++){
      if (boards[i].id === event.target.value){
        board = boards[i];
        break;
      }
    }
    for (i = 0; i < board.digitalPins.length; i++){
      pins.push(board.digitalPins[i]);
    }
    for (i = 0; i < board.analogPins.length; i++){
      pins.push(board.analogPins[i]);
    }

    setAvailablePins(pins);
    setSelectedBoard(event.target.value);
  };

  const handleAddComponent = () => {
    if (!selectedBoard) {
      setOpenPleaseChooseABoardModal(true);
      return;
    }
    let array = [...componentsArray];
    let componentNumber = 0;
    if (componentsArray.length) {
      componentNumber = componentsArray[componentsArray.length - 1].componentNumber + 1;
    }
    const addNew = { componentNumber: componentNumber, type: "", function: "", pinsNeeded: { numberOfPins: 0, pins: [] }, arduinoPins: [], subComponents: [] };
    array.push(addNew);
    setComponentsArray(array);
  };

  const handleDeleteComponent = (index) => {
    let array = [...componentsArray];
    
    // Identify the type of component being deleted
    const deletedComponent = array[index];
    
    // Get the pins that were assigned to this component
    const pinsToRelease = deletedComponent.arduinoPins.map(pin => pin.pinNumber);

    // Remove these pins from the selectedPins array, but only if they are not used by any other component
    setSelectedPins(prevSelectedPins => {
        let updatedPins = [...prevSelectedPins];
        pinsToRelease.forEach(pin => {
            // Check if this pin or its shared pin is used by any other component
            const isStillUsed = array.some((comp, compIndex) => 
                compIndex !== index && comp.arduinoPins.some(p => p.pinNumber === pin || p.pinNumber === findSharedPin(pin))
            );

            if (!isStillUsed) {
                // If the pin and its shared pin are not used anymore, remove them
                updatedPins = updatedPins.filter(p => p !== pin && p !== findSharedPin(pin));
            }
        });
        return updatedPins;
    });

    // Remove the component from the array
    array.splice(index, 1);
  
    setComponentsArray(array);
};

  
  // Helper function to check if a pin is shared (associated with multiplexers)
  const isSharedPin = (pin) => {
    const sharedPins = ["4", "5", "6", "7"];
    return sharedPins.includes(pin);
  };
    
  const handleResetComponents = () => {
    setComponentsArray([]);
    setSelectedPins([]);
  };

 

  const getComponents = async () => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(`/api/v1/controllers/new-controller/get`, {}, { headers: { 'Content-Type': 'application/json' } });
      if (response.data.success) {
        setBoards(response.data.data.boards);
        setComponentTypes(response.data.data.components);
        populateMIDIArrays();

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  




  const handleSaveController = async () => {
    console.log(componentsArray);

    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(`/api/v1/controllers/new-controller/add`, {componentsArray}, { headers: { 'Content-Type': 'application/json' } });
      if (response.data.success) {
        console.log(response.data)

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      getComponents();
    } else {
      navigate("/login");
    }
  }, [loggedIn]);

  const handlePinChange = (e, componentIndex, pinIndex) => {
    const updatedArray = [...componentsArray];
    const previousPin = updatedArray[componentIndex].arduinoPins[pinIndex] ? updatedArray[componentIndex].arduinoPins[pinIndex].pinNumber : null;
    const newPinNumber = e.target.value;

    updatedArray[componentIndex].arduinoPins[pinIndex] = { pinNumber: newPinNumber };

    setComponentsArray(updatedArray);

    // Remove the previous pin and its shared pin from the selectedPins array
    if (previousPin) {
      setSelectedPins((prevSelectedPins) =>
        prevSelectedPins.filter((pin) => pin !== previousPin && pin !== findSharedPin(previousPin))
      );
    }

    // Add the new pin and its shared pin to the selectedPins array
    const sharedPin = findSharedPin(newPinNumber);
    if (sharedPin) {
      setSelectedPins((prevSelectedPins) => [...prevSelectedPins, newPinNumber, sharedPin]);
    } else {
      setSelectedPins((prevSelectedPins) => [...prevSelectedPins, newPinNumber]);
    }

    console.log('Updated selectedPins:', selectedPins);
};

const findSharedPin = (pinNumber) => {
    const pin = availablePins.find(p => p.pinNumber === pinNumber);
    return pin ? pin.sharedWith : null;
};


  const updateComponentsWithNewPins = (newPins, currentComponentIndex) => {
    const updatedArray = [...componentsArray];
    
    for (let i = 0; i < currentComponentIndex; i++) {
      if (componentsArray[i].type.includes("Multiplexer")) {
        updatedArray[i].arduinoPins = updatedArray[i].arduinoPins.map(pinObj => 
          newPins.includes(pinObj.pinNumber) ? { ...pinObj, pinNumber: "" } : pinObj
        );
      }
    }

    setComponentsArray(updatedArray);
  };

  const handleComponentTypeChange = (e, componentIndex) => {
    const updatedArray = [...componentsArray];
    const selectedType = e.target.value;

    if (selectedType === "rotary-encoder" || selectedType === "rotary-encoder-button"){
      let existingEncoder = updatedArray.find(ct => ct.type === "rotary-encoder" || ct.type === "rotary-encoder-button");
      if (existingEncoder){
        setOpenEncoderAlreadyExistsModal(true);
        return;
      }
    }
    const selectedComponent = componentTypes.find(ct => ct.id === selectedType);
    const requiredPins = selectedComponent.pinsNeeded.pins.filter(pin => pin.required).map(pin => pin.requiredPin);

    // Preselect required pins for the current component
    const arduinoPins = selectedComponent.pinsNeeded.pins.map(pin =>
        pin.required ? { pinNumber: pin.requiredPin, disabled: true } : { pinNumber: "", disabled: false }
    );

    // Deselect required pins from other components unless they are also required
    updatedArray.forEach((component, index) => {
        if (index !== componentIndex) {
            component.arduinoPins = component.arduinoPins.map(pinObj => {
                if (requiredPins.includes(pinObj.pinNumber) && !isRequiredPinForComponent(pinObj.pinNumber, component)) {
                    return { ...pinObj, pinNumber: "" };
                }
                return pinObj;
            });
        }
    });

    // Update the selected pins array
    const preselectedPins = requiredPins.filter(pin => !selectedPins.includes(pin));
    setSelectedPins(prevSelectedPins => [...prevSelectedPins, ...preselectedPins]);

    // Update the component with the new type and preselected pins
    updatedArray[componentIndex] = {
        ...componentsArray[componentIndex],
        type: selectedType,
        function: "",
        functionCommand: "",
        pinsNeeded: selectedComponent.pinsNeeded,
        arduinoPins: arduinoPins,
        subComponents: []
    };

    if (selectedType === "multiplexer8Ch") {
        // Generate 8 sub-components
        const subComponents = Array(8).fill(null).map((_, subIndex) => ({
            componentNumber: `${componentIndex + 1}-${subIndex + 1}`,
            type: "",
            function: "",
            functionCommand: "",
            pinsNeeded: { numberOfPins: 1, pins: [{ type: "digital", required: false, requiredPin: null }] },
            arduinoPins: [{ pinNumber: "", disabled: false }],
        }));
        updatedArray[componentIndex].subComponents = subComponents;
    } else if (selectedType === "multiplexer16Ch") {
        // Generate 16 sub-components
        const subComponents = Array(16).fill(null).map((_, subIndex) => ({
            componentNumber: `${componentIndex + 1}-${subIndex + 1}`,
            type: "",
            function: "",
            functionCommand: "",
            pinsNeeded: { numberOfPins: 1, pins: [{ type: "digital", required: false, requiredPin: null }] },
            arduinoPins: [{ pinNumber: "", disabled: false }],
        }));
        updatedArray[componentIndex].subComponents = subComponents;
    }

    setComponentsArray(updatedArray);
};

const isRequiredPinForComponent = (pinNumber, component) => {
    return component.pinsNeeded.pins.some(pin => pin.required && pin.requiredPin === pinNumber);
};


  const handleSubComponentTypeChange = (e, componentIndex, subIndex) => {
    const updatedArray = [...componentsArray];
    const selectedType = e.target.value;
    const isPotentiometer = selectedType === "potentiometer";
    
    updatedArray[componentIndex].subComponents[subIndex].type = selectedType;

    if (subIndex === 0) {
      // Enforce rules based on the first sub-component type
      if (isPotentiometer) {
        // If first sub-component is potentiometer, all must be potentiometers
        updatedArray[componentIndex].subComponents = updatedArray[componentIndex].subComponents.map((subComponent, index) => ({
          ...subComponent,
          type: index === 0 ? selectedType : "potentiometer",
          arduinoPins: [{ pinNumber: "", disabled: false }],
          function: "",
          functionCommand: ""
        }));
      } else {
        // If first sub-component is not potentiometer, exclude potentiometer from others
        updatedArray[componentIndex].subComponents = updatedArray[componentIndex].subComponents.map((subComponent, index) => ({
          ...subComponent,
          type: index === 0 ? selectedType : "",
          arduinoPins: [{ pinNumber: "", disabled: false }],
          function: "",
          functionCommand: ""
        }));
      }
    }

    setComponentsArray(updatedArray);
  };

  const handleSubComponentFunctionChange = (e, componentIndex, subIndex) => {
    const updatedArray = [...componentsArray];
    updatedArray[componentIndex].subComponents[subIndex].function = e.target.value;
    setComponentsArray(updatedArray);
  };

  const handleSubComponentFunctionCommandChange = (e, componentIndex, subIndex) => {
    const updatedArray = [...componentsArray];
    updatedArray[componentIndex].subComponents[subIndex].functionCommand = e.target.value;
    setComponentsArray(updatedArray);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Controllers" title="New Controller" />

      <CustomLoadingIndicator isActive={loadingIndicatorActive} />
      <CustomModal
        open={openPleaseChooseABoardModal}
        handleClose={() => setOpenPleaseChooseABoardModal(false)}
        elements={elementsSetOpenPleaseChooseABoardModal}
        confirmFunction={() => setOpenPleaseChooseABoardModal(false)}
      />
      <CustomModal
        open={openEncoderAlreadyExistsModal}
        handleClose={() => setOpenEncoderAlreadyExistsModal(false)}
        elements={elementsSetOpenEncoderAlreadyExistsModal}
        confirmFunction={() => setOpenEncoderAlreadyExistsModal(false)}
      />

      <div className='flex justify-end mb-10 -mt-24 mr-10 md:mr-20'>
        <CustomTooltipComponent
          icon={MdAdd}
          tooltipText="Add New Component"
          onClick={handleAddComponent}
          currentColor={currentColor}
        />
        &nbsp;
         <CustomTooltipComponent
          icon={MdSave}
          tooltipText="Save Controller"
          onClick={handleSaveController}
          currentColor={currentColor}
        />
      </div>

      <div>
        <div style={containerStyle}>
          {renderSelectedBoard(boards, selectedBoard)}
          {selectedBoard === "arduino-pro-micro" && (
            <ArduinoProMicroDiagram
              allPins={availablePins} // This should be an array of objects with a unique pinNumber
              selectedPins={selectedPins} // Extract pin numbers for comparison
            />
          )}

          <SearchableDropdown
            items={boards}
            label="Choose Board"
            value={selectedBoard}
            onChange={handleSelectBoard}
            renderMenuItem={renderBoardMenuItem}
          />

          {componentsArray.map((component, componentIndex) => (
            <Grid key={componentIndex} item xs={12} sm={6} md={4} style={{ border: "1px solid lightgrey", borderRadius: "5px", padding: "10px" }}>
              <div style={itemStyle}>


              <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 style={{fontWeight: "bold"}}>Component {component.componentNumber}</h1>
                <CustomTooltipComponent
                  icon={MdDelete}
                  tooltipText="Delete Component"
                  onClick={() => handleDeleteComponent(componentIndex)}
                  currentColor={themeColorsUsable.red}
                />
              </span>

                <br />
                <SearchableDropdown
                  items={componentTypes}
                  fullWidth
                  label="Component Type"
                  value={componentsArray[componentIndex].type}
                  onChange={(e) => handleComponentTypeChange(e, componentIndex)}
                  renderMenuItem={renderComponentTypeMenuItem}
                />
                <br />
                {componentsArray[componentIndex].type &&
                  <SearchableDropdown
                    items={componentTypes.find(ct => ct.id === componentsArray[componentIndex].type)?.functions || []}
                    fullWidth
                    label="Component Function"
                    value={componentsArray[componentIndex].function}
                    onChange={(e) => {
                      const updatedArray = [...componentsArray];
                      updatedArray[componentIndex] = {
                        ...component,
                        function: e.target.value,
                        functionCommand: ""
                      };
                      setComponentsArray(updatedArray);
                    }}
                    renderMenuItem={renderComponentFunctionMenuItem}
                  />
                }
                <br />
                {componentsArray[componentIndex].function && componentsArray[componentIndex].function !== "multiplex" &&
                  <SearchableDropdown
                    items={componentsArray[componentIndex].function.includes("note") ? midiNoteFunctions : componentsArray[componentIndex].function.includes("cc-message") ? midiCCFunctions : []}
                    fullWidth
                    label="Function Command"
                    value={componentsArray[componentIndex].functionCommand}
                    onChange={(e) => {
                      const updatedArray = [...componentsArray];
                      updatedArray[componentIndex] = {
                        ...component,
                        functionCommand: e.target.value,
                      };
                      setComponentsArray(updatedArray);
                    }}
                    renderMenuItem={renderComponentFunctionMenuItem}
                  />
                }
                <br />
                {componentsArray[componentIndex].pinsNeeded.pins.map((pin, pinIndex) => (
                  <div key={pinIndex}>
                    <SearchableDropdown
                      items={availablePins}
                      fullWidth
                      type="arduino-pin"
                      label={`Arduino Pin ${pinIndex + 1}`}
                      value={componentsArray[componentIndex].arduinoPins[pinIndex]?.pinNumber || ""}
                      onChange={(e) => handlePinChange(e, componentIndex, pinIndex)}
                      renderMenuItem={(option) => (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body1">
                            {option.pinNumber}
                          </Typography>
                        </Box>
                      )}
                      disabledItems={selectedPins}
                      disabled={componentsArray[componentIndex].arduinoPins[pinIndex]?.disabled || false}
                    />
                    <br />
                  </div>
                ))}
                {componentsArray[componentIndex].subComponents && componentsArray[componentIndex].subComponents.map((subComponent, subIndex) => (
                  <div key={subIndex} style={{ marginLeft: "20px" }}>
                    <Typography variant="span">Sub-Component {subComponent.componentNumber}</Typography>
                    <SearchableDropdown
                      items={subIndex === 0
                        ? componentTypes.filter(ct => !["multiplexer16Ch", "multiplexer8Ch", "rotary-encoder-button", "rotary-encoder"].includes(ct.id))
                        : subComponent.type === "potentiometer"
                          ? componentTypes.filter(ct => ct.id === "potentiometer")
                          : subComponent.type === "potentiometer"
                          ? componentTypes.filter(ct => ![ "multiplexer16Ch", "multiplexer8Ch", "rotary-encoder-button", "rotary-encoder"].includes(ct.id)) : componentTypes.filter(ct => !["potentiometer", "multiplexer16Ch", "multiplexer8Ch", "rotary-encoder-button", "rotary-encoder"].includes(ct.id))}
                      fullWidth
                      label="Sub-Component Type"
                      value={subComponent.type}
                      onChange={(e) => handleSubComponentTypeChange(e, componentIndex, subIndex)}
                      renderMenuItem={renderComponentTypeMenuItem}
                      disabled={subIndex !== 0 && !componentsArray[componentIndex].subComponents[0].type}
                    />
                    <br />
                    {subComponent.type &&
                      <SearchableDropdown
                        items={componentTypes.find(ct => ct.id === subComponent.type)?.functions || []}
                        fullWidth
                        label="Sub-Component Function"
                        value={subComponent.function}
                        onChange={(e) => handleSubComponentFunctionChange(e, componentIndex, subIndex)}
                        renderMenuItem={renderComponentFunctionMenuItem}
                      />
                    }
                    <br />
                    {subComponent.function &&
                      <SearchableDropdown
                        items={subComponent.function.includes("note") ? midiNoteFunctions : subComponent.function.includes("cc-message") ? midiCCFunctions : []}
                        fullWidth
                        label="Sub-Component Function Command"
                        value={subComponent.functionCommand}
                        onChange={(e) => handleSubComponentFunctionCommandChange(e, componentIndex, subIndex)}
                        renderMenuItem={renderComponentFunctionMenuItem}
                      />
                    }
                    <br />
                  </div>
                ))}
              </div>
            </Grid>
          ))}

        </div>
      </div>
    </div>
  );
};

export default NewController;
